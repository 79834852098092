import { AccessAlarm, Article } from '@mui/icons-material'
import { Avatar, Box, List, ListItem, ListItemAvatar, ListItemText, Typography } from '@mui/material'
import React, { useMemo } from 'react'

function App() {
    const applicationList = useMemo(
        () => [
            { appName: 'Report generator', appPort: 8191, appIcon: <Article />, background: '#845134' },
            { appName: 'Current time', appPort: 8193, appIcon: <AccessAlarm />, background: '#ff4a4a' },
        ],
        []
    )

    return (
        <Box>
            <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', marginBottom: '25px' }}>
                <img
                    src={process.env.PUBLIC_URL + '/images/dfu.png'}
                    alt="DFU"
                    style={{
                        maxWidth: 180 + 'px',
                        maxHeight: '100%',
                        objectFit: 'contain',
                        padding: 25 + 'px',
                    }}
                />
                <Typography variant="h4" sx={{ color: '#5f809b' }}>
                    DFU Portal
                </Typography>
            </Box>
            <List
                sx={{
                    padding: 0,
                    background: (theme) => theme.palette.background.default,
                    cursor: 'pointer',
                    '& .MuiListItem-root:hover': {
                        background: '#eee',
                    },
                }}
            >
                {applicationList.map((app, index) => (
                    <ListItem
                        key={index}
                        sx={{
                            borderRadius: 0,
                            borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
                        }}
                    >
                        <ListItemAvatar>
                            <Avatar sx={{ background: app.background }}>{app.appIcon}</Avatar>
                        </ListItemAvatar>
                        <ListItemText
                            primary={app.appName}
                            secondary={`Port: ${app.appPort}`}
                            onClick={() => {
                                window.location = `${process.env.REACT_APP_APP_URL}:${app.appPort}`
                            }}
                        />
                    </ListItem>
                ))}
            </List>
        </Box>
    )
}

export default App
